@font-face {
    font-display:swap;
    font-family:Tektur;
    font-weight:400;
    src:url(fonts/Tektur-Regular.ttf) format("truetype") 
   }
   @font-face {
    font-display:swap;
    font-family:Tektur;
    font-weight:500;
    src:url(fonts/Tektur-Medium.ttf) format("truetype")
   }
   @font-face {
    font-display:swap;
    font-family:Tektur;
    font-weight:600;
    src:url(fonts/Tektur-SemiBold.ttf) format("truetype")
   }

body{
    /* background-color: #050510; */
    background: linear-gradient(90deg,#ffc812 20%,#ff9509) !important;
    /* font-family: 'Raleway', sans-serif !important;  */
    color: #FFFFFF!important;
    font-family:Tektur !important;
    
}
h2{
    font-family:Tektur !important;
}
.xxx{
    background-image: url(img/bcg.svg);
    background-repeat: no-repeat !important;
    background-position: center !important;
    /* padding-bottom: 100px; */
}
.use-metamask, .use-walletConnect{
    /* background-color: transparent !important;
    color: #bc984d !important;
    background-size: 52px 302px;
    border: #bc984d solid 2px !important;
    border-radius: 25px !important; */

        /* max-width: 211px ; */
        /* width: 100% !important;
        height: 51px !important;
        line-height: 51px !important;
        border-radius: 5px !important;
        background-image: linear-gradient(0deg, #ff8500 0%, #f3c75f 100%) !important;
        font-size: 20px !important;
        letter-spacing: 1px !important;
        font-weight: 700 !important;
        font-family: 'Rajdhani', sans-serif !important;
        text-align: center !important;
        transition: all .5s !important;
        color:  #FFF !important; */

        width: 100% !important;
        height: 51px !important;
        line-height: 51px !important;
        /* border-radius: 5px !important; */
        border-radius: 0px !important;
    
        /* background-image: linear-gradient(0deg, #ff8500 0%, #f3c75f 100%) !important; */
        background: linear-gradient(90deg,#ffc812 20%,#ff9509) !important;
    
        font-size: 20px !important;
        letter-spacing: 1px !important;
        font-weight: 700 !important;
        font-family: 'Rajdhani', sans-serif !important;
        text-align: center !important;
        transition: all .5s !important;
        color:  #FFF !important;
        font: 500 15px Tektur !important;
    
    
}
/*.use-metamask{*/
/*    background-image: url("img/btn-green-up.svg") !important;*/
/*}*/
/*.use-walletConnect{*/
/*    background-image: url("img/btn-green-down.svg") !important;*/

/*}*/
.filled-box{
    /*background-color: #17A68B !important;*/
    background: #292f307e !important;
    border: 2px #FFF solid !important;

    mix-blend-mode: normal !important;;
    /* border-radius: 16px !important; */
    color: #FFF;
    
}
.filled-box.outer{
    min-height: 600px !important;
}
.text {
    color: #FFF;
}

.submit-button{
	font: 500 15px Tektur !important;
	padding: 10px !important;
	min-width: 320px !important;
	min-height: 44px !important;
	max-height: 70px !important;
	display: inline-flex !important;
	position: absolute !important;
	transform: translateY(-100%) !important;
	box-shadow: 0 20px 50px rgba(0,0,0,.6705882352941176) !important;
	overflow: hidden !important;
	background: linear-gradient(90deg,#ffc812 20%,#ff9509) !important;
}
/* .submit-button{ */
    /*background-image: url("img/btn-black-up.svg") !important;*/
    /*background-size: 332px 48px !important;*/
    /*background-color: transparent !important;*/
    /*color: #fff !important;*/
    /*margin-top: 30px;*/
    
    
    /* background-color: #bc984d !important;
    color: #fff !important;
    margin-top: 30px;
    border-radius: 25px !important; */

/*     
    width: 100% !important;
    height: 51px !important;
    line-height: 51px !important;
    border-radius: 5px !important;
    background-image: linear-gradient(0deg, #ff8500 0%, #f3c75f 100%) !important;
    font-size: 20px !important;
    letter-spacing: 1px !important;
    font-weight: 700 !important;
    font-family: 'Rajdhani', sans-serif !important;
    text-align: center !important;
    transition: all .5s !important;
    color:  #FFF !important; */
/* }  */
.modal-content {
    background: #292f307e !important;
    mix-blend-mode: normal !important;
    /* border-radius: 16px !important; */
    color: #FFFFFF !important;
    border: #FFF 2px solid !important;
}
.modal-content h2{
    font-weight: normal !important;
    color: #FFFFFF !important;
}
.modal-content p{
    color: #FFFFFF !important;
}
.switch-network{
    /*background-image: url("img/btn-black-up-short.svg") !important;*/
    /*background-size: 192px 48px !important;*/
    /* background-color: #bc984d !important;
    color: #fff !important;
    border-radius: 25px !important; */

    width: 100% !important;
    height: 51px !important;
    line-height: 51px !important;
    /* border-radius: 5px !important; */
    border-radius: 0px !important;

    /* background-image: linear-gradient(0deg, #ff8500 0%, #f3c75f 100%) !important; */
	background: linear-gradient(90deg,#ffc812 20%,#ff9509) !important;

    font-size: 20px !important;
    letter-spacing: 1px !important;
    font-weight: 700 !important;
    font-family: 'Rajdhani', sans-serif !important;
    text-align: center !important;
    transition: all .5s !important;
    color:  #FFF !important;
    font: 500 15px Tektur !important;
}
.disconnect{
    /*background-image: url("img/btn-white-down.svg") !important;*/
    /*background-size: 128px 40px !important;*/
    background-color: transparent !important;
    color: #FFFFFF !important;

}
.copy-icon, .info-icon{
    color: #FFFFFF !important;
}
.restake-button{
    /*background-image: url("img/btn-black-up-short.svg") !important;*/
    /*background-size: 75px 32px !important;*/
    /*background-color: transparent !important;*/
    /*color: #fff !important;*/
    /*margin-top: 30px;*/
    
    /* background-color: #bc984d !important;
    color: #fff !important;
    margin-top: 30px;
    border-radius: 25px !important; */

    width: 100% !important;
    /* height: 51px !important; */
    /* line-height: 51px !important; */
    /* border-radius: 5px !important; */
    /* background-image: linear-gradient(0deg, #ff8500 0%, #f3c75f 100%) !important; */
    /* font-size: 20px !important; */
    /* letter-spacing: 1px !important; */
    /* font-weight: 700 !important; */
    /* font-family: 'Rajdhani', sans-serif !important; */
    /* text-align: center !important; */
    /* transition: all .5s !important; */
    /* color:  #FFF !important; */


    font-size: 20px !important;
    letter-spacing: 1px !important;
    font-weight: 700 !important;
    font-family: 'Rajdhani', sans-serif !important;
    text-align: center !important;
    transition: all .5s !important;
    color:  #FFF !important;
    font: 500 15px Tektur !important;
    border-radius: 0px !important;


}
.claim-button{
    color: #FFFFFF !important;

}
.request-unstake-button
{
    color: #FFFFFF !important;
}
.stake-button{
    /*background-image: url("img/btn-black-up.svg") !important;*/
    /*background-size: 332px 48px !important;*/
    /*background-color: transparent !important;*/
    /*color: #fff !important;*/
    /*margin-top: 30px;*/

    /* background-color: #bc984d !important;
    color: #fff !important;
    margin-top: 30px;
    border-radius: 25px !important; */

    width: 100% !important;
    height: 51px !important;
    line-height: 51px !important;
    /* border-radius: 5px !important; */
    border-radius: 0px !important;

    /* background-image: linear-gradient(0deg, #ff8500 0%, #f3c75f 100%) !important; */
	background: linear-gradient(90deg,#ffc812 20%,#ff9509) !important;

    font-size: 20px !important;
    letter-spacing: 1px !important;
    font-weight: 700 !important;
    font-family: 'Rajdhani', sans-serif !important;
    text-align: center !important;
    transition: all .5s !important;
    color:  #FFF !important;
    font: 500 15px Tektur !important;


    /* font: 500 15px Tektur !important; */
	/* padding: 10px !important; */
	/* min-width: 320px !important; */
	/* min-height: 44px !important; */
	/* max-height: 70px !important; */
	/* display: inline-flex !important; */
	/* position: absolute !important; */
	/* transform: translateY(-100%) !important; */
	/* box-shadow: 0 20px 50px rgba(0,0,0,.6705882352941176) !important; */
	/* overflow: hidden !important; */
	/* background: linear-gradient(90deg,#ffc812 20%,#ff9509) !important; */
    /* border-radius: 0px !important; */
    /* margin-top: 30px !important; */



}
.approve{
    /*background-image: url("img/btn-black-up.svg") !important;*/
    /*background-size: 332px 48px !important;*/
    /*background-color: transparent !important;*/
    /*color: #fff !important;*/
    /*margin-top: 30px;*/
    
    
    /* background-color: #bc984d !important;
    color: #fff !important;
    margin-top: 30px;
    border-radius: 25px !important; */
/* 
    width: 100% !important;
    height: 51px !important;
    line-height: 51px !important;
    border-radius: 5px !important;
    background-image: linear-gradient(0deg, #ff8500 0%, #f3c75f 100%) !important;
    font-size: 20px !important;
    letter-spacing: 1px !important;
    font-weight: 700 !important;
    font-family: 'Rajdhani', sans-serif !important;
    text-align: center !important;
    transition: all .5s !important;
    color:  #FFF !important; */

    width: 100% !important;
    height: 51px !important;
    line-height: 51px !important;
    /* border-radius: 5px !important; */
    border-radius: 0px !important;

    /* background-image: linear-gradient(0deg, #ff8500 0%, #f3c75f 100%) !important; */
	background: linear-gradient(90deg,#ffc812 20%,#ff9509) !important;

    font-size: 20px !important;
    letter-spacing: 1px !important;
    font-weight: 700 !important;
    font-family: 'Rajdhani', sans-serif !important;
    text-align: center !important;
    transition: all .5s !important;
    color:  #FFF !important;
    font: 500 15px Tektur !important;

}
.confirm, .confirm-request-unstake-button{
    /*background-image: url("img/btn-black-down.svg") !important;*/
    /*background-size: 332px 48px !important;*/
    /*background-color: transparent !important;*/
    /*color: #fff !important;*/
    /*margin-top: 30px;*/
    
    /* background-color: #bc984d !important;
    color: #fff !important;
    margin-top: 30px;
    border-radius: 25px !important; */
/* 
    width: 100% !important;
    height: 51px !important;
    line-height: 51px !important;
    border-radius: 5px !important;
    background-image: linear-gradient(0deg, #ff8500 0%, #f3c75f 100%) !important;
    font-size: 20px !important;
    letter-spacing: 1px !important;
    font-weight: 700 !important;
    font-family: 'Rajdhani', sans-serif !important;
    text-align: center !important;
    transition: all .5s !important;
    color:  #FFF !important; */

    width: 100% !important;
    height: 51px !important;
    line-height: 51px !important;
    /* border-radius: 5px !important; */
    border-radius: 0px !important;

    /* background-image: linear-gradient(0deg, #ff8500 0%, #f3c75f 100%) !important; */
	background: linear-gradient(90deg,#ffc812 20%,#ff9509) !important;

    font-size: 20px !important;
    letter-spacing: 1px !important;
    font-weight: 700 !important;
    font-family: 'Rajdhani', sans-serif !important;
    text-align: center !important;
    transition: all .5s !important;
    color:  #FFF !important;
    font: 500 15px Tektur !important;

}
a{
    color: #ffc010 !important;
}
/*.unstake-with-fee-button{*/
/*    background-image: url("img/btn-black-up.svg") !important;*/
/*    background-size: 282px 48px !important;*/
/*    background-color: transparent !important;*/
/*    color: #fff !important;*/
/*    margin-top: 30px;*/
/*}*/
